<template>
  <FormField v-slot="{ error: hasError }" :errorText="creativesError" hideLabel>
    <DropdownList
      ref="dropdownRef"
      trigger="manual"
      :hideOnClick="false"
      :width="`${dropdownWidth}px`"
      @clickOutside="hideDropdown"
    >
      <template #default="{ show }">
        <InputText
          :id="getId('creatives.creatives')"
          v-model="searchQueryDebounced"
          :error="hasError"
          :disabled="loadingAll"
          placeholder="Search for a creative"
          icon="search"
          autocomplete="off"
          data-test-id="creatives-search"
          @focus="show"
          @click="show"
        />
      </template>

      <template #dropdown>
        <Paper disablePadding>
          <CreativesSearchItemBase v-if="fetching || loadingAll" noHover>
            <Typography variant="overline1" color="textSecondary" uppercase>
              {{ loadingAll ? 'Selecting all creatives...' : 'Searching...' }}
            </Typography>
          </CreativesSearchItemBase>

          <ErrorState v-else-if="error" :error="error" @retry="retry()" />

          <template v-else-if="searchedCreatives.length > 0">
            <Paper class="sticky top-0 z-20">
              <CreativesSearchItemBase noHover>
                <Checkbox
                  id="selectAllRecent"
                  :key="String(isAllSelected)"
                  :modelValue="isAllSelected"
                  :indeterminate="isAnySelected && !isAllSelected"
                  @update:modelValue="onSelectAll()"
                />
                <Typography variant="overline1" color="textSecondary" uppercase>
                  {{ isShowingRecentCreatives ? 'Recent' : 'Results' }} ({{ total }})
                </Typography>
              </CreativesSearchItemBase>
            </Paper>
            <Box flex col>
              <CreativesSearchListItem
                v-for="creative of searchedCreatives"
                :key="creative.id"
                :creative="creative"
                :selected="isCreativeSelected(creative)"
                :static="shouldHaveStaticIndication(creative, endemicFormatId)"
                :displaySize="displaySize"
                data-test-id="creatives-search-row"
                @update:selected="handleSelect(creative)"
              />
              <InfiniteScroll
                v-if="!fetching"
                :disabled="!hasNextPage"
                :fetchNextPage="requestNextPage"
              />
            </Box>
          </template>
          <template v-else>
            <CreativesSearchItemBase noHover>
              <Typography variant="overline1" color="textSecondary" uppercase>
                No results found
              </Typography>
            </CreativesSearchItemBase>
          </template>
        </Paper>
      </template>
    </DropdownList>
  </FormField>
</template>

<script setup lang="ts">
import { ComponentPublicInstance, computed, ref } from 'vue'
import { useElementSize } from '@vueuse/core'
import { Box, Checkbox, DropdownList, ErrorState, FormField, InfiniteScroll, InputText, Paper, Typography } from '@lasso/luikit'
import { ComponentExposed } from '@lasso/shared/types'

import { sleep } from '@lasso/shared/utils'

import { AdGroupCreative } from '../../../../shared/creatives'

import { useCardCreatives } from '../useCardCreatives'
import { shouldHaveStaticIndication } from '../utils'

import CreativesSearchItemBase from './CreativesSearchItemBase.vue'
import CreativesSearchListItem from './CreativesSearchListItem.vue'
import { useCreativesSearch } from './useCreativesSearch'

const {
  isCreativeSelected,
  setCreativesSelected,
  toggleCreativeSelected,
  endemicFormatId,
  displaySize,
} = useCardCreatives()!

const {
  fetching,
  error,
  retry,
  searchedCreatives,
  searchQueryDebounced,
  total,
  isShowingRecentCreatives,
  requestNextPage,
  hasNextPage,
  getId,
  creativesError,
  loadAllResults,
} = useCreativesSearch()!

const dropdownRef = ref<ComponentPublicInstance & ComponentExposed<typeof DropdownList> | null>(null)

const { width: dropdownWidth } = useElementSize(dropdownRef)

const hideDropdown = () => {
  dropdownRef.value?.hide()
}

const handleSelect = (creative: AdGroupCreative) => {
  toggleCreativeSelected(creative)
  hideDropdown()
}

const isAllSelected = computed(() => searchedCreatives.value.every(isCreativeSelected))
const isAnySelected = computed(() => searchedCreatives.value.some(isCreativeSelected))
const loadingAll = ref(false)

const onSelectAll = async () => {
  if (loadingAll.value) {
    return
  }

  loadingAll.value = true
  const creatives = await loadAllResults()

  if (!creatives) {
    loadingAll.value = false
    return
  }

  hideDropdown()
  setCreativesSelected(creatives, !creatives.every(isCreativeSelected))
  // Wait for dropdown animation
  await sleep(200)

  loadingAll.value = false
}
</script>
