<template>
  <TableCellName
    :hovered="hovered || previewShown"
    :href="previewUrl"
    target="_blank"
    :clickable="Boolean(previewUrl)"
  >
    <template #chip>
      <Chip size="2xs" variant="outlined" :label="creative.id" />
      <Chip v-if="shouldHaveStaticIndication(creative, endemicFormatId)" size="2xs" variant="outlined" label="Static" />
    </template>
    {{ value }}
    <template #actions>
      <CreativePreviewButton
        v-model:shown="previewShown"
        :creative="creative"
        :href="previewUrl"
        :channelId="channelId"
      />
      <ButtonIcon icon="delete_outline" @click="setCreativesSelected(creative, false)" />
    </template>
  </TableCellName>
</template>

<script setup lang="ts">
import { ButtonIcon, Chip, TableCellName } from '@lasso/luikit'

import { computed, ref } from 'vue'

import { shouldHaveStaticIndication } from '../utils'
import { CreativePreviewButton } from '../../../shared/CreativesPreviewButton'
import { AdGroupCreative } from '../../../../shared'
import { useCardCreatives } from '../useCardCreatives'

const { value, creative, hovered } = defineProps<{
  value: string
  creative: AdGroupCreative
  hovered: boolean
}>()

const {
  endemicFormatId,
  channelId,
  setCreativesSelected,
  getCreativePreviewUrl,
} = useCardCreatives()!

const previewShown = ref(false)
const previewUrl = computed(() => getCreativePreviewUrl(creative))
</script>
