import { useApiManual } from '@lasso/shared/hooks'
import { CreativeRotationType, useActivationApi } from '@lasso/api-activation/activation'

import { CreativesFormInput } from './types'

export const useCardCreativesSubmit = () => {
  const { requestThrows: linkCreativesInternal } = useApiManual(useActivationApi().linkAdGroupCreatives)

  const linkCreatives = async ({ rotationType, creatives, adGroupId }: {
    adGroupId: number
  } & CreativesFormInput) => {
    const adGroupCreativesArray = creatives.map((creative) => {
      return {
        creativeID: creative.id,
        weight: rotationType === CreativeRotationType.WEIGHTED ? creative.weight : null,
      }
    })

    await linkCreativesInternal({
      adGroupID: adGroupId,
      creativeRotationType: rotationType,
      adGroupCreativesArray,
    })
  }

  return { linkCreatives }
}
