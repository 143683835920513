<template>
  <CreativesSearchItemBase :for="id">
    <Checkbox :id="id" v-model="selectedInternal" />
    <Chip size="2xs" variant="outlined" :label="creative.id" />
    <Chip v-if="static" size="2xs" variant="outlined" label="Static" />
    <Chip v-if="displaySize" size="2xs" variant="outlined" :label="getBannerSize(creative)" />
    <Chip
      size="2xs"
      variant="outlined"
      :color="creativeStatusChipMap[creative.creativeStatus].color"
      :label="creativeStatusChipMap[creative.creativeStatus].label"
    />
    <Typography variant="body2" display="block" whiteSpace="nowrap">
      {{ creative.name }}
    </Typography>
  </CreativesSearchItemBase>
</template>

<script setup lang="ts">
import { Checkbox, Chip, Typography } from '@lasso/luikit'
import { useVModel } from '@vueuse/core'
import { computed } from 'vue'

import { AdGroupCreative, creativeStatusChipMap, getBannerSize } from '../../../../shared/creatives'

import CreativesSearchItemBase from './CreativesSearchItemBase.vue'

const props = defineProps<{
  selected: boolean
  creative: AdGroupCreative
  static: boolean
  displaySize?: boolean
}>()

const emit = defineEmits<{
  'update:selected': [boolean]
}>()

const selectedInternal = useVModel(props, 'selected', emit)

const id = computed(() => `creative-${props.creative.id}`)
</script>
