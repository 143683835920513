import { CreativeRotationType } from '@lasso/api-activation/activation'
import { SelectOptionType } from '@lasso/luikit'

export const rotationTypeWhenLeversEnabled = CreativeRotationType.INHERITED
export const defaultRotationType = CreativeRotationType.AUTO

export const rotationTypeMap: { [K in CreativeRotationType]: SelectOptionType<K> } = {
  [CreativeRotationType.AUTO]: {
    value: CreativeRotationType.AUTO,
    label: 'Auto optimize',
  },
  [CreativeRotationType.EVEN]: {
    value: CreativeRotationType.EVEN,
    label: 'Evenly weight',
  },
  [CreativeRotationType.WEIGHTED]: {
    value: CreativeRotationType.WEIGHTED,
    label: 'Manually weight',
  },
  [CreativeRotationType.INHERITED]: {
    value: CreativeRotationType.INHERITED,
    label: 'Inherit weight from levers',
  },
}
