<template>
  <Card title="Creative Rotation" opened collapse withDivider>
    <FormField :id="getId('creatives.rotationType')" v-slot="{ id }" hideLabel>
      <Box flex spaceX="6" spaceY="4" wrap="wrap">
        <RadioButton
          v-for="type in rotationTypeOptions"
          :id="id"
          :key="type.value"
          v-model="rotationType"
          :value="type.value"
          :title="type.label"
          :disabled="advancedOptimization"
        />
      </Box>
    </FormField>
  </Card>
</template>

<script setup lang="ts">
import { Box, Card, FormField, RadioButton } from '@lasso/luikit'

import { useCardCreatives } from './useCardCreatives'

const { rotationType, advancedOptimization, rotationTypeOptions, getId } = useCardCreatives()!
</script>
